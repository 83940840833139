import React from 'react'
import { PageProps } from 'gatsby'
import {
  Head,
  WideCarousel,
  Button,
  Releases,
  Lots,
  Footer,
  Blog,
  LazyLoad,
  Modal,
} from '~/components'
import { useApi } from '~/siteApi'
import * as st from '~/assets/styl/Home.module.styl'

type Context = {
  banners: Array<{
    title: string
    description: string
    cta: string
    url: string
    external: boolean
    image: string
    mobile: string
    film: boolean
  }>
  launchs: never
  enterprises: never
}

const Home = ({ pageContext }: PageProps) => {
  const { banners, launchs, enterprises }: Context = useApi(
    pageContext,
    'page-home'
  )

  const [openVideo, setOpenVideo] = React.useState(true)

  return (
    <>
      <Head />

      <header>
        <WideCarousel home>
          {(banners || []).map(
            (
              { title, description, url, external, cta, image, mobile, film },
              key
            ) => (
              <LazyLoad
                key={key}
                className={st.slide}
                src={image}
                mobileSrc={mobile}
                url={url}
                external={external}
                film={film}
              >
                <div className={st.hero}>
                  <h1>{title}</h1>
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: description?.replace(/\n/, '<br/>'),
                    }}
                  ></h2>
                  {cta && <Button>{cta}</Button>}
                </div>
              </LazyLoad>
            )
          )}
        </WideCarousel>
      </header>

      <Releases items={launchs || []} />

      <Lots items={enterprises || []} />

      <Blog />

      <Footer />

      <Modal
        open={openVideo}
        onClose={() => setOpenVideo(false)}
        className="video-modal"
      >
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/g4_2J992SfA"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Modal>
    </>
  )
}

export default Home
